import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-size: 0.9rem;
  line-height: 1.6;
`;

const Title = styled.h1`
  color: #8B4513;
  text-align: center;
  margin-bottom: 2rem;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #4FA0E8;
  margin-bottom: 1rem;
`;

function PrivacyPolicy() {
  return (
    <PolicyContainer>
      <Title>プライバシーポリシー</Title>
      <Section>
        <SectionTitle>1. 収集する情報</SectionTitle>
        <p>当社は、お問い合わせフォームを通じて以下の情報を収集します：</p>
        <ul>
          <li>氏名</li>
          <li>メールアドレス</li>
          <li>お問い合わせ内容</li>
        </ul>
      </Section>

      <Section>
        <SectionTitle>2. 情報の使用目的</SectionTitle>
        <p>収集した情報は、以下の目的でのみ使用されます：</p>
        <ul>
          <li>お問い合わせへの回答</li>
          <li>サービス改善のための統計分析（個人を特定しない形で）</li>
        </ul>
      </Section>

      <Section>
        <SectionTitle>3. 情報の保護</SectionTitle>
        <p>当社は、収集した個人情報の保護に最大限の注意を払い、不正アクセス、紛失、改ざん、漏洩などを防ぐために適切な措置を講じます。</p>
      </Section>

      <Section>
        <SectionTitle>4. 第三者への提供</SectionTitle>
        <p>法令に基づく場合を除き、お客様の同意なく第三者に個人情報を提供することはありません。</p>
      </Section>

      <Section>
        <SectionTitle>5. 個人情報の開示・訂正・削除</SectionTitle>
        <p>お客様ご自身の個人情報の開示、訂正、削除をご希望の場合は、お問い合わせフォームよりご連絡ください。</p>
      </Section>

      <Section>
        <SectionTitle>6. プライバシーポリシーの変更</SectionTitle>
        <p>本ポリシーの内容は、法令変更などを踏まえて適宜見直しを行い、変更する場合があります。変更後のプライバシーポリシーは、当ウェブサイトに掲載した時点から効力を生じるものとします。</p>
      </Section>

      <Section>
        <SectionTitle>7. お問い合わせ</SectionTitle>
        <p>本ポリシーに関するお問い合わせは、お問い合わせフォームよりご連絡ください。</p>
      </Section>
      </PolicyContainer>
  );
}

export default PrivacyPolicy;