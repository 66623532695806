import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";
import backgroundImage from '../assets/contact.png';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Modal from '../components/Modal';
import PrivacyPolicy from './PrivacyPolicy';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  animation: ${fadeIn} 1s ease-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 800px;
  margin: 2rem;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  animation: ${slideIn} 0.7s ease-out;
`;

const Title = styled.h1`
  color: #8B4513;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100px;
    height: 4px;
    background-color: #4FA0E8;
    transform: translateX(-50%);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  transition: all 0.3s ease;
  pointer-events: none;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem 15px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    border-color: #4FA0E8;
    box-shadow: 0 0 0 2px rgba(79, 160, 232, 0.2);
  }

  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    top: 0;
    font-size: 0.8rem;
    background-color: white;
    padding: 0 5px;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem 15px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  transition: all 0.3s ease;

  &:focus {
    border-color: #4FA0E8;
    box-shadow: 0 0 0 2px rgba(79, 160, 232, 0.2);
  }
`;

const SubmitButton = styled.button`
  background-color: #8B4513;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background-color: #A0522D;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ErrorMessage = styled.div`
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const CompletionMessage = styled.div`
  text-align: center;
  font-size: 1.5rem;
  color: #4FA0E8;
  margin-top: 2rem;
  animation: ${fadeIn} 1s ease-out;
`;

const RecaptchaContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  
  input {
    margin-right: 0.5rem;
  }

  label {
    font-size: 0.9rem;
  }
`;

const PrivacyPolicyLink = styled.button`
  background: none;
  border: none;
  color: #4FA0E8;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.9rem;
`;

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    confirmEmail: '',
    subject: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [agreeToPolicy, setAgreeToPolicy] = useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        console.log('Submitting form data:', { ...formData, recaptchaToken: recaptchaValue, agreeToPolicy });
        //const response = await axios.post('http://localhost:3001/api/contact', {
        const response = await axios.post('https://arqc4a1wlk.execute-api.ap-northeast-1.amazonaws.com/Prod/api/contact', {
          ...formData,
          recaptchaToken: recaptchaValue
        });
        console.log('Response:', response.data);
        setIsSubmitted(true);
      } catch (error) {
        console.error('Error submitting form:', error.response?.data || error.message);
        setSubmitError('お問い合わせの送信に失敗しました　再度お試しください');
      }
    }
  };

  const openPrivacyPolicy = (e) => {
    e.preventDefault();
    setIsPrivacyPolicyOpen(true);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = 'お名前は必須です';
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Emailアドレスは必須です';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = '無効なEmailアドレスです';
      isValid = false;
    }

    if (formData.email !== formData.confirmEmail) {
      newErrors.confirmEmail = 'Emailアドレスが一致しません';
      isValid = false;
    }

    if (!formData.subject.trim()) {
      newErrors.subject = 'お問い合わせ件名は必須です';
      isValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = 'お問い合わせ内容は必須です';
      isValid = false;
    }

    if (!recaptchaValue) {
      newErrors.recaptcha = 'reCAPTCHAが未チェックです';
      isValid = false;
    }

    if (!agreeToPolicy) {
      newErrors.policy = 'プライバシーポリシーに同意してください';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  if (isSubmitted) {
    return (
      <PageContainer>
        <ContentWrapper>
          <CompletionMessage>
            <h2>お問い合わせをいただきありがとうございました</h2>
            <p>返信にお時間をいただく場合がございますがご了承ください</p>
          </CompletionMessage>
        </ContentWrapper>
      </PageContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>問い合わせフォーム - 合同会社ベアパウ（BearPaw）</title>
        <meta name="description" content="ベアパウ（BearPaw）へのお仕事やお問い合わせ" />
        <link rel="canonical" href="https://www.bear-paw.co.jp/contact" />
      </Helmet>
      <PageContainer>
        <ContentWrapper>
          <Title>お問い合わせ</Title>
          {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder=" "
              />
              <Label htmlFor="name">お名前</Label>
              {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </InputGroup>

            <InputGroup>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder=" "
              />
              <Label htmlFor="email">Email</Label>
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </InputGroup>

            <InputGroup>
              <Input
                type="email"
                id="confirmEmail"
                name="confirmEmail"
                value={formData.confirmEmail}
                onChange={handleChange}
                placeholder=" "
              />
              <Label htmlFor="confirmEmail">Email (確認)</Label>
              {errors.confirmEmail && <ErrorMessage>{errors.confirmEmail}</ErrorMessage>}
            </InputGroup>

            <InputGroup>
              <Input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder=" "
              />
              <Label htmlFor="subject">件名</Label>
              {errors.subject && <ErrorMessage>{errors.subject}</ErrorMessage>}
            </InputGroup>

            <InputGroup>
              <TextArea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="お問い合わせ内容"
              />
              {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
            </InputGroup>

            <RecaptchaContainer>
              <ReCAPTCHA
                sitekey="6LfUCgwqAAAAAPfc4tNeEMealP4C65LWzz6Uv8ra"
                onChange={handleRecaptchaChange}
              />
            </RecaptchaContainer>
            {errors.recaptcha && <ErrorMessage>{errors.recaptcha}</ErrorMessage>}

            <CheckboxContainer>
              <input
                type="checkbox"
                id="privacy-policy"
                checked={agreeToPolicy}
                onChange={(e) => setAgreeToPolicy(e.target.checked)}
              />
              <label htmlFor="privacy-policy">
                <PrivacyPolicyLink onClick={openPrivacyPolicy}>プライバシーポリシー</PrivacyPolicyLink>に同意します
              </label>
            </CheckboxContainer>
            {errors.policy && <ErrorMessage>{errors.policy}</ErrorMessage>}

            <SubmitButton type="submit">送信</SubmitButton>
          </Form>
          <Modal
            isOpen={isPrivacyPolicyOpen}
            onClose={() => setIsPrivacyPolicyOpen(false)}
          >
            <PrivacyPolicy />
          </Modal>
        </ContentWrapper>
      </PageContainer>
    </>
  );
}

export default Contact;