import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Recruit from './pages/Recruit';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import GlobalStyle from './GlobalStyle';

const AppContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding-top: 80px; // ヘッダーの高さに合わせて調整
  padding-bottom: 60px; // フッターの高さに合わせて調整

  @media (max-width: 768px) {
    padding-top: 60px; // モバイル用にパディングを調整
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
    <Router>
      <Helmet>
        <title>合同会社ベアパウ（BearPaw） - テクノロジーと創造性の融合</title>
        <meta name="description" content="ベアパウ（BearPaw） - テクノロジーを力に、遊び心を形に。システム開発、ゲーム開発、AWS構築支援など、革新的なソリューションを提供します。" />
        <meta name="keywords" content="合同会社ベアパウ, BearPaw, システム開発, ゲーム開発, AWS, クラウド, テクノロジー" />
        <meta property="og:title" content="BearPaw - テクノロジーと創造性の融合" />
        <meta property="og:description" content="BearPaw - テクノロジーを力に、遊び心を形に。革新的なソリューションを提供します。" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bear-paw.co.jp" />
        <meta property="og:image" content="https://www.bear-paw.co.jp/og-image.jpg" />
        <link rel="canonical" href="https://www.bear-paw.co.jp" />
      </Helmet>
      <GlobalStyle />
      <AppContainer>
        <Header />
        <ContentWrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/recruit" element={<Recruit />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </ContentWrapper>
        <Footer />
      </AppContainer>
    </Router>
    </>
  );
}

export default App;