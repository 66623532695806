import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  body {
    background-color: #FFFFFF;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito', sans-serif;
  }

  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  /* レスポンシブデザインのための追加スタイル */
  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }

  /* タッチデバイス用の調整 */
  @media (hover: none) {
    a:hover, button:hover {
      transition: none;
    }
  }

  /* スクロールバーのカスタマイズ（オプション） */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default GlobalStyle;