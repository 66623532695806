import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';
import LogoTitle from '../assets/logo_title.png';

const HeaderContainer = styled.header`
  background-color: rgba(139, 69, 19, 1);
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  flex-shrink: 0;
`;

const LogoImg = styled.img`
  height: 40px;
  margin-right: 10px;

  @media (max-width: 768px) {
    height: 30px;
  }
`;

const LogoTitleImg = styled.img`
  height: 35px;
  margin-right: 20px;

  @media (max-width: 768px) {
    height: 25px;
    margin-right: 10px;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    margin-top: 1rem;
    background-color: rgba(139, 69, 19, 0.9);
    padding: 1rem;
  }
`;

const StyledLink = styled(Link)`
  color: #FFF8DC;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover {
    color: #4FA0E8;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: #FFF8DC;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('header')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HeaderContainer>
      <LogoContainer to="/">
        <LogoImg src={Logo} alt="BearPaw Logo" />
        <LogoTitleImg src={LogoTitle} alt="BearPaw Logo Title" />
      </LogoContainer>
      <MenuButton onClick={toggleMenu}>☰</MenuButton>
      <NavLinks isOpen={isMenuOpen}>
        <StyledLink to="/about">会社概要</StyledLink>
        <StyledLink to="/services">サービス</StyledLink>
        <StyledLink to="/recruit">採用</StyledLink>
        <StyledLink to="/contact">お問い合わせ</StyledLink>
      </NavLinks>
    </HeaderContainer>
  );
}

export default Header;