import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaAmazon, FaAws, FaCode, FaComment, FaGamepad, FaHandshake, FaPaw } from 'react-icons/fa';
import backgroundImage from '../assets/services.png';
import { Helmet } from 'react-helmet';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  animation: ${fadeIn} 1s ease-out;
  padding: 2rem 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  margin: 2rem;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  animation: ${slideIn} 0.7s ease-out;

  @media (max-width: 768px) {
    margin: 1rem;
    padding: 1.5rem;
  }
`;

const Title = styled.h1`
  color: #8B4513;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100px;
    height: 4px;
    background-color: #4FA0E8;
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const ServiceCard = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const ServiceIcon = styled.div`
  font-size: 3rem;
  color: #4FA0E8;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const ServiceTitle = styled.h2`
  color: #8B4513;
  margin-bottom: 1rem;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const ServiceDescription = styled.p`
  color: #666;
  line-height: 1.6;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ContactSection = styled.div`
  margin-top: 4rem;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const ContactText = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ContactButton = styled.a`
  display: inline-block;
  background-color: #4FA0E8;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3A75A0;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
`;

function Services() {
  return (
    <>
      <Helmet>
        <title>サービス - 合同会社ベアパウ（BearPaw）</title>
        <meta name="description" content="ベアパウ（BearPaw）が提供するサービスについて" />
        <link rel="canonical" href="https://www.bear-paw.co.jp/services" />
      </Helmet>
    <PageContainer>
      <ContentWrapper>
        <Title>サービス</Title>
        <ServicesGrid>
          <ServiceCard>
            <ServiceIcon>
              <FaCode />
            </ServiceIcon>
            <ServiceTitle>システム開発</ServiceTitle>
            <ServiceDescription>
              お客様のニーズに合わせたカスタムソフトウェアの開発を行います。
              Webアプリケーション、モバイルアプリ、業務システムなど、幅広い分野に対応しています。
              上流から下流まで一貫してお任せいただくことも可能ですし、プロジェクトの部分的な開発委託にも対応しております。
              開発言語に関しては社内のアサイン可能なリソース状況によりますので、お問い合わせください。
            </ServiceDescription>
          </ServiceCard>
          <ServiceCard>
            <ServiceIcon>
              <FaGamepad />
            </ServiceIcon>
            <ServiceTitle>ゲーム開発</ServiceTitle>
            <ServiceDescription>
              PC向けSteamゲーム、モバイルゲームなど複数のプラットフォームに対応したゲーム開発をお受けしています。
              企画から参画させていただくことも可能ですし、開発の一部を委託することもできます。
              対応可能な開発エンジンはUnrealEngine、Unityに限らせていただきます。
              2D、3D問わず、幅広いジャンルのゲームに対応しておりますので、お気軽にお問い合わせください。
            </ServiceDescription>
          </ServiceCard>
          <ServiceCard>
            <ServiceIcon>
              <FaAws />
            </ServiceIcon>
            <ServiceTitle>AWS構築支援</ServiceTitle>
            <ServiceDescription>
                AWSを利用したシステムの構築支援を行います。
                クラウドサーバーの構築、データベースの設計、セキュリティ対策など、お客様のニーズに合わせたサービスを提供します。
                毎月のAWSコストを抑えたい、新規サービス立ち上げに際し適切なサービスの組み合わせが知りたい、など、元AWSのSolutionsArchitectである代表が対応いたします。
                AWSインフラの構築支援・構成レビューもお受けしておりますし、これから初めてAWS導入を希望されるお客様もお気軽にお問い合わせください。
            </ServiceDescription>
          </ServiceCard>
        </ServicesGrid><br/>
        <ServicesGrid>
          <ServiceCard>
            <ServiceIcon>
              <FaComment />
            </ServiceIcon>
            <ServiceTitle>組織コンサルティング</ServiceTitle>
            <ServiceDescription>
              開発組織の運営にお悩みの企業様に対して、コンサルティングサービスを提供しています。
              プロジェクトの進行管理、エンジニアのスキルアップ、開発プロセスの改善など、幅広い課題に対応します。
              また、社内に技術ナレッジがなかなか貯まらないですとか、離職率が高い、といった課題に対しても、解決策を提案いたします。
              システムで致命的な問題が起きた際の対策や復旧オペレーションを想定出来ているかなど、企業としての致命傷を回避するための対策も提案いたします。
            </ServiceDescription>
          </ServiceCard>
          <ServiceCard>
            <ServiceIcon>
              <FaHandshake />
            </ServiceIcon>
            <ServiceTitle>技術顧問</ServiceTitle>
            <ServiceDescription>
              ご依頼いただくには継続的な作業がないですとか、コスト的に常駐依頼は難しい、といった場合でも、週次・月次など定期的なサイクルで課題をヒアリングし改善案をご提案する技術顧問契約をお受けしております。
              開発組織について・AWSインフラについて・セキュリティについて・プロジェクト進行管理について　など、幅広い課題に対応いたします。長期的に少しずつ改善を進めていきたい、といった企業様にお勧めです。
              費用に関しましては、ご相談の上、お客様のご予算に合わせたプランをご提案いたしますので、お気軽にお問い合わせください。
            </ServiceDescription>
          </ServiceCard>
          <ServiceCard>
            <ServiceIcon>
              <FaPaw />
            </ServiceIcon>
            <ServiceTitle>ペット用品開発</ServiceTitle>
            <ServiceDescription>
              愛するペットのための革新的な製品を開発しています。
              デザイン性やコストパフォーマンスはもちろんながら、食品であれば安全性、オモチャや装身具であれば耐久性など、ペットとその飼い主の生活をより豊かにする製品を提供します。
              今後、開発中の商品の販売を予定しておりますのでどうぞご期待ください。
            </ServiceDescription>
          </ServiceCard>
        </ServicesGrid>
        <ContactSection>
          <ContactText>
            お客様のプロジェクトやアイデアについて、詳しくお聞かせください。<br/>
            私たちがどのようにお手伝いできるか、ご相談させていただきます。
          </ContactText>
        </ContactSection>
      </ContentWrapper>
    </PageContainer>
    </>
  );
}

export default Services;