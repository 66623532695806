import React from 'react';
import styled, { keyframes } from 'styled-components';
import backgroundImage from '../assets/recruit.png';
import { Helmet } from 'react-helmet';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  animation: ${fadeIn} 1s ease-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1000px;
  margin: 2rem;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  animation: ${slideIn} 0.7s ease-out;
`;

const Title = styled.h1`
  color: #8B4513;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100px;
    height: 4px;
    background-color: #4FA0E8;
    transform: translateX(-50%);
  }
`;

const Section = styled.section`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  color: #4FA0E8;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 600;
`;

const JobList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const JobItem = styled.li`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const JobTitle = styled.h3`
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
`;

const JobDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const MessageSection = styled.div`
  background-color: rgba(79, 160, 232, 0.1);
  border-radius: 10px;
  padding: 2rem;
  margin-top: 3rem;
`;

const Message = styled.p`
  color: #333;
  line-height: 1.8;
  font-size: 1.1rem;
  text-align: center;
`;

function Recruit() {
  return (
    <>
      <Helmet>
        <title>採用情報 - 合同会社ベアパウ（BearPaw）</title>
        <meta name="description" content="ベアパウ（BearPaw）の採用情報 求める技術スタックや人物像" />
        <link rel="canonical" href="https://www.bear-paw.co.jp/recruit" />
      </Helmet>
    <PageContainer>
      <ContentWrapper>
        <Title>採用情報</Title>
        
        <Section>
          <SectionTitle>募集職種</SectionTitle>
          <JobList>
            <JobItem>
              <JobTitle>フルスタックエンジニア</JobTitle>
              <JobDescription>
                フロントエンドからバックエンドまで、幅広い技術スタックを活用して
                日々移りゆくトレンドを取り入れたモダンなWebアプリケーションの開発に携わっていただきます。
                Python、PHP、React、Node.js、AWS等の経験がある方を歓迎します。
                自社サービスの開発に関しては開発者の得意な言語やアーキテクチャの意向を尊重します。
              </JobDescription>
            </JobItem>
            <JobItem>
              <JobTitle>モバイルアプリエンジニア</JobTitle>
              <JobDescription>
                Swift・Kotlinによるモバイルアプリケーションの開発に携わっていただきます。
                Swift・Kotlin・React Native・Flutterいずれかの開発経験に自信のある方。
                CocoaPods、Carthage、Gradle等のライブラリ管理ツールの使用経験がある方、React Native、Flutter等でクロスプラットフォーム開発経験がある方は歓迎します。
              </JobDescription>
            </JobItem>
            <JobItem>
              <JobTitle>UX/UIデザイナー</JobTitle>
              <JobDescription>
                ユーザー体験重視の設計思想に基づき、直感的で美しいインターフェースを
                デザインしていただきます。Figma、Zeplin等の開発者と連携しやすいツールの使用経験がある方、
                WEBサイト・モバイルアプリ・モバイルゲームのいずれかのデザイン経験がある方を歓迎します。
              </JobDescription>
            </JobItem>
            <JobItem>
              <JobTitle>ゲームエンジニア</JobTitle>
              <JobDescription>
                UnrealEngineやUnityを用いたゲーム開発に携わっていただきます。要件の実現に際しゲームアプリケーションのパフォーマンスを意識したコード設計を行える方を歓迎します。
                VFX、3Dモデリング、アニメーション等のスキルをお持ちの方は優遇いたします。
              </JobDescription>
            </JobItem>
            <JobItem>
              <JobTitle>インフラエンジニア</JobTitle>
              <JobDescription>
                自社サービスの立ち上げ、または受託開発案件においてAWSによるインフラの設計・構築・運用を行っていただきます。
                ECS、RDS、S3、Codepipeline、SecretManager、Elasticache等のAWSサービスを活用したインフラ構築経験がある方を歓迎します。
                AWS Certified Solutions Architect - Professional または AWS Certified - Specialtyをお持ちだと尚可です。また、TerraformやCloudFormation等のIaCツールの使用経験がある方は優遇いたします。
              </JobDescription>
            </JobItem>
          </JobList>
        </Section>

        <MessageSection>
          <Message>
            BearPawは常に最新のトレンドや作業効率化ツールを追い求める「モダン主義」な人材を歓迎します。<br/>
            詳細な応募要項や待遇については、お問い合わせページからご連絡ください。
          </Message>
        </MessageSection>
      </ContentWrapper>
    </PageContainer>
    </>
  );
}

export default Recruit;