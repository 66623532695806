import React from 'react';
import styled, { keyframes } from 'styled-components';
import backgroundImage from '../assets/top.png';
import { Helmet } from 'react-helmet';

const backgroundFadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const textFadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const HomeContainer = styled.div`
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: ${backgroundFadeIn} 3s ease-in-out;
`;

const StylishText = styled.h1`
  color: #FFFFFF;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
  margin-bottom: 1rem;
  font-size: 4rem;
  font-family: 'Nunito', sans-serif;
  position: relative;
  animation: ${textFadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 200px;
    height: 3px;
    background-color: #4FA0E8;
    transform: translateX(-50%);

    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

const Subtitle = styled.h2`
  color: #FFFFFF;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2rem;
  font-family: 'Open Sans', sans-serif;
  animation: ${textFadeIn} 2.5s ease-in-out;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

function Home() {
  return (
    <>
      <Helmet>
        <title>合同会社ベアパウ（BearPaw） - テクノロジーを力に、遊び心を形に</title>
        <meta name="description" content="ベアパウ（BearPaw）は、システム開発、ゲーム開発、AWS構築支援などの革新的なソリューションを提供する会社です。" />
        <link rel="canonical" href="https://www.bear-paw.co.jp/" />
      </Helmet>
      <HomeContainer>
        <StylishText>テクノロジーを力に</StylishText>
        <Subtitle>遊び心を形に</Subtitle>
      </HomeContainer>
    </>
  );
}

export default Home;