import React from 'react';
import styled from 'styled-components';

const MapContainer = styled.div`
  width: 100%;
  height: 450px;
  margin-top: 1rem;
`;

const MapIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

function Map() {
  return (
    <MapContainer>
      <MapIframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2723.4772738236948!2d139.7138748592185!3d35.7316643714865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d8f5f350aaf%3A0xcc6e4bb51c7412bb!2z44OR44O844Kv44OP44Kk44OE5rGg6KKL!5e0!3m2!1sja!2sjp!4v1720506408645!5m2!1sja!2sjp"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="パークハイツ池袋の地図"
      />
    </MapContainer>
  );
}

export default Map;