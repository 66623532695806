import React from 'react';
import styled, { keyframes } from 'styled-components';
import Map from '../components/Map';
import backgroundImage from '../assets/top.png'; 
import { Helmet } from 'react-helmet';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  animation: ${fadeIn} 1s ease-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  margin: 2rem;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  animation: ${slideIn} 0.7s ease-out;
`;

const Title = styled.h1`
  color: #8B4513;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100px;
    height: 4px;
    background-color: #4FA0E8;
    transform: translateX(-50%);
  }
`;

const Section = styled.section`
  margin-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const InfoContainer = styled.div`
  flex: 1;
  min-width: 300px;
  margin-right: 2rem;
`;

const InfoList = styled.dl`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
`;

const InfoTerm = styled.dt`
  font-weight: bold;
  color: #8B4513;
  font-size: 1.1rem;
`;

const InfoDescription = styled.dd`
  margin-left: 0;
  color: #333;
  font-size: 1rem;
`;

const AccessInfo = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  background-color: rgba(79, 160, 232, 0.1);
  padding: 1rem;
  border-radius: 5px;
`;

const StationName = styled.span`
  font-weight: bold;
  color: #4FA0E8;
`;

const MapContainer = styled.div`
  flex: 1;
  min-width: 300px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const MessageSection = styled.section`
  margin-top: 4rem;
  background-color: rgba(79, 160, 232, 0.1);
  padding: 2rem;
  border-radius: 10px;
`;

const MessageTitle = styled.h2`
  color: #8B4513;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-family: 'Nunito', sans-serif;
`;

const MessageContent = styled.p`
  color: #333;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;
`;

const Signature = styled.div`
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;
  margin-top: 2rem;
`;

function About() {
  return (
    <>
      <Helmet>
        <title>会社概要 - 合同会社ベアパウ（BearPaw）</title>
        <meta name="description" content="ベアパウ（BearPaw）の会社概要  私たちのビジョンと使命について" />
        <link rel="canonical" href="https://www.bear-paw.co.jp/about" />
      </Helmet>
    <PageContainer>
      <ContentWrapper>
        <Title>会社概要</Title>
        
        <Section>
          <InfoContainer>
            <InfoList>
              <InfoTerm>社名</InfoTerm>
              <InfoDescription>合同会社ベアパウ（BearPaw LLC）</InfoDescription>
              <InfoTerm>代表</InfoTerm>
              <InfoDescription>神﨑 翔一朗</InfoDescription>
              <InfoTerm>設立日</InfoTerm>
              <InfoDescription>2024年4月16日</InfoDescription>
              <InfoTerm>事業内容</InfoTerm>
              <InfoDescription>
                システム開発<br/>
                モバイルアプリケーション開発<br/>
                ホームページ制作・保守<br/>
                AWS構築支援及びコンサルティング<br/>
                ゲームソフトウェア開発<br/>
                ペット用品開発及び販売
              </InfoDescription>
              <InfoTerm>所在地</InfoTerm>
              <InfoDescription>
                東京都豊島区東池袋１丁目１７－１１<br />パークハイツ池袋１１０１
              </InfoDescription>
            </InfoList>
            <AccessInfo>
              ＪＲ、東京メトロ、西武 各線<StationName>池袋</StationName>駅<br />
              <StationName>池袋</StationName>駅地下通路 29番出口より徒歩3分
            </AccessInfo>
          </InfoContainer>
          <MapContainer>
            <Map />
          </MapContainer>
        </Section>
        
        <MessageSection>
          <MessageTitle>代表メッセージ</MessageTitle>
          <MessageContent>
            弊社ではSier業界・ソーシャルゲーム業界・人材業界と多様な業界のシステム領域を渡り歩いてきた経験を活かし、WEBアプリケーションからモバイルアプリケーション、Unityを用いたゲーム開発まで幅広く案件対応させていただいております。<br /><br />
            また、Amazon Web Service Japan 合同会社（AWS）にて スタートアップのお客様専門の Solutions Architect として在籍し、同領域の数多くのお客様と顔を合わせて接客してきた経験から、
            創業から間もない企業においてクラウドインフラの構築に不安や懸念をお持ちのケースが多い事も理解しています。<br /><br />
            「限りあるリソースからインフラエンジニアを雇用する事が難しい」ですとか、「SEが兼任でインフラの面倒を見ていて構成に自信がない」あるいは
            「システム部門を持っておらずサービス立ち上げに伴い知見が不足している」といったお客様も多いかと存じます。<br /><br />
            代表がキャリアの約半分をスタートアップ領域で過ごしてきた弊社では、そういった人材的な台所事情や経営事情を考慮した上で、お客様のビジネスに最適な技術支援をさせていただきます。<br /><br />
            CEO・CTO・SREとしての経験も活かし、組織運営に関するご相談および隔週や月次での面談を実施する技術顧問契約もお受けしています。<br/>
            様々な分野・フェーズ・規模の企業を中から見てきたノウハウをもって、技術・経営の両面からお客様のビジネスをサポートさせていただきますので、お気軽にご相談ください。
          </MessageContent>
          <Signature>代表　神﨑 翔一朗</Signature>
        </MessageSection>
      </ContentWrapper>
    </PageContainer>
    </>
  );
}

export default About;